.whats-included-container {
  width: 80%;
  margin: auto
}

.whats-included-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, 1fr);
}

.whats-included-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  justify-content: center;
  font-family: "Dancing Script";
}

.whats-included-item {
  display: grid;
  align-items: start;
  grid-auto-flow: column;
  justify-content: start;
  grid-gap: 6px;
  align-items: center;
}

.whats-included-icon {
  width: 100px;
  height: 100px;
  size: 100px;
  font-size: 5em;
}


@media (max-width: 500px) {
  .whats-included-container {
    width: 95%;
    margin: auto
  }
  
  .whats-included-header {
    flex-direction: column;
    
  }

  .whats-included-grid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
  }
}
