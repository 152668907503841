.payment-card-foreground .recommended {
  width: 200px;
  margin-top: -15px;
  margin-left: 3px;
  background-color: var(--dark-grey);

  padding: 6px;
  border-radius: 12px;
}

.payment-card-foreground .noir {
  color: var(--gold);
}

.payment-card-foreground .blanc {
  color: var(--pastel-purple);
}

.payment-card-foreground .tier-title {
  font-size: 2em;
  text-align: center;
  font-family: "Dancing Script";
}

.payment-card-foreground-noir {
  background: linear-gradient(to bottom, var(--goldAlpha), var(--dark-grey));
}

.payment-card-foreground .tier-description {
  color: var(--pale-grey);
  font-size: 0.7em;
  text-align: center;
}

.payment-card-foreground .price {
  display: flex;
  justify-content: center;
  align-items: end;
}

.payment-card-foreground .included {
  width: 80%;
  margin: auto;
}

.payment-card-foreground .price .symbol {
}

.payment-card-foreground .price .cost {
  font-size: 3em;
  margin-bottom: -0.2em;
}

.payment-card-foreground .price .monthly {
}

@keyframes grow {
  0% {
    box-shadow: 0px 0px 0px 0px var(--dark-grey);
  }
  100% {
    height: 100%;
    width: 100%;
    box-shadow: 0px 0px 6px 0px var(--black);
  }
}

.payment-card-container {
  padding: 0px;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  justify-content: center;
  align-content: center;
  margin: auto;
  height: 98%;
  width: 98%;
  border-radius: 12px;
}

.payment-card-container:hover {
  transform: translateZ(0);
  animation: forwards grow 0.5s;
  backface-visibility: hidden;
  -webkit-font-smoothing: subpixel-antialiased;
}

.card-price {
  display: grid;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
}
