:root {
  --gold: #937316;
  --goldAlpha: #9374167a;
  --dark-grey: #333333;
  --dark-grey66: #333333a8;
  --light-grey: #cccccc;
  --black: #000000;
}

/* Blanc Theme */
:root {
  --pastel-pink: #ffb6c1;
  --pastel-pink66: #ffb6c1a8;
  --pastel-pinkalpha: #ffb6c171;
  --pastel-purple: #e6e6fa;
  --light-background: #ffffff;
  --pale-grey: #f5f5f5;
  --soft-blue: #87ceeb;
  --pastel-green: #77dd77;
  --light-purple: #d8bfd8;
  --warm-yellow: #ffd700;
  --soft-pink: #ffb6c1;
  --primary: var(--soft-pink);
  --primary-alpha: #ffb6c1a8;
  --secondary: var(--light-purple);
}

@font-face {
  font-family: "Dancing Script";
  src: url("../public/DancingScript-VariableFont_wght.ttf") format("truetype");
}

.emphasised {
  font-family: "Dancing Script";
  font-size: 1.8em;
}

.emphasised-underline {
  background-image: url("../public/underline.png");
  background-repeat: no-repeat;
  background-position: 90%;
}

.emphasised-underline-noir {
  background-image: url("../public/underline-gold.png");
  background-repeat: no-repeat;
  background-position: 50% 110%;
}

.emphasised-underline-blanc {
  background-image: url("../public/underline-pink.png");
  background-repeat: no-repeat;
  background-position: 50% 110%;
}

.full-width-rotate {
  width: 200vw; /* Twice the width of the viewport */
  height: 100px; /* Set your desired height */
  position: absolute;
  top: -50px; /* Adjust this value to position the element correctly */
  left: 50%; /* Center horizontally */
  transform-origin: top center; /* Rotate from top center */
  transform: rotate(45deg);
}

.app-noir {
}

/* Blanc Theme */
.app-blanc {
  height: 100vh;
}

.blanc-gradient {
  /* background: linear-gradient(
    to bottom,
    var),
    var(--pastel-purple)
  ); */
}

.text-with-gradient {
  /* Set the text color to transparent to let the gradient show through */
  color: transparent;

  /* Apply the gradient as a background image */
  background: linear-gradient(to bottom, var(--primary), var(--secondary));
  /* You may need to adjust background size, background clip, and other properties based on your design */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Safari fix */
  font-size: 2em;
  margin: 0;
  transition: font-size 0.1s ease;
}

.text-with-gradient-min {
  font-size: 1.5em;
}

.top-bar-emphasised {
  font-size: 1.4em;
}

.top-bar-emphasised-min {
  font-size: 1.2em;
}

.noir-gradient {
  color: transparent;

  /* Apply the gradient as a background image */
  /* You may need to adjust background size, background clip, and other properties based on your design */
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent; /* Safari fix */
  background: linear-gradient(to bottom, var(--light-grey), var(--dark-grey));
}

.top-bar-header-text {
  color: var(--dark-grey);
}

.top-bar-layout-container {
  z-index: 99;
  width: 100%;
  height: 0px;
  top: 0px;
  position: fixed;
}

.top-bar-layout-container-min {
  position: fixed;
}

.top-bar-layout {
  padding: 12px 12px;
  height: 85px;
  border-bottom: solid 5px var(--primary);
  display: grid;
  grid-template-columns: 80px auto 1fr 150px;
  margin: auto;
  background: var(--dark-grey);
  align-items: flex-end;
  margin-bottom: 40px;
  grid-template-rows: 100%;
  transition: height 0.1s ease-in-out;
}

.top-bar-layout-min {
  height: 50px;
  
}

.top-bar-logo {
  transition: transform 0.2s ease-in-out;
  grid-row: 1 / span 1;
  height: 60px;
  width: 60px;
  justify-self: flex-end;
  cursor: pointer;
  
}

.top-bar-logo-min {
  grid-row: 1 / span 1;
  height: 40px;
  width: 40px;
}

.card {
  border-radius: 10px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-content {
  width: 80%;
  position: relative;
  margin: auto;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 25px auto;
}

.main-content-first {
  margin-top: 100px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.buy-now-btn {
  /* Set button styles */
  padding: 10px 20px;
  height: 30px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1.7em;
  font-weight: bold;
  width: 90px;
  text-decoration: none;
  text-align: center;

  color: var(--dark-grey);
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primary);
}

.top-bar-box-container {
  display: grid;
  justify-content: center;
  grid-auto-flow: column;
  position: absolute;
  width: 100%;
  overflow: hidden;
}

.top-bar-box {
  width: 78vw;
  padding: 24px;
  height: 80px;
  margin: 12px 1vw;
  top: 100px;

  background-size: 132%;
  background-position: 62%;
}

.top-bar-box-blanc {
  background-image: url("../public/beautybox_top.jpg");
  box-shadow: 0px 0px 5px 0px var(--pastel-purple);
  border-radius: 50px 50px 50px 50px;
  left: 10vw;
  top: -160px;
  margin-right: 0vw;
}

.top-bar-box-noir {
  background-image: url("../public/beautybox_top.jpg");
  box-shadow: 0px 0px 5px 0px var(--gold);

  border-radius: 50px 50px 50px 50px;
  display: flex;
  flex-direction: column;
  align-items: end;
  text-align: end;
}

@keyframes blanc-moveLeft {
  0% {
    height: 400px;
  }

  50% {
    height: 80px;
  }
  100% {
    height: 80px;
  }
}

@keyframes blanc-moveRight {
  0% {
  }
  50% {
    height: 400px;
  }
  100% {
    height: 400px;
  }
}

.top-bar-box-blanc-move-left {
  animation-name: blanc-moveLeft;
  animation-duration: 1s; /* Adjust the duration as needed */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  cursor: pointer;
}

.top-bar-box-blanc-move-right {
  animation-name: blanc-moveRight;
  animation-duration: 1s; /* Adjust the duration as needed */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.top-bar-box-blanc-move-left:hover {
  height: 90px;
}

@keyframes noir-moveLeft {
  0% {
    margin-left: -75vw;
  }
  50% {
    height: 400px;
  }
  100% {
    margin-left: 82vw;
    height: 400px;
  }
}

@keyframes noir-moveRight {
  0% {
    margin-left: 76vw;
    height: 400px;
  }
  50% {
    height: 80px;
  }
  100% {
    margin-left: -82vw;
    height: 80px;
  }
}

.top-bar-box-noir-move-left {
  animation-name: noir-moveLeft;
  animation-duration: 1s; /* Adjust the duration as needed */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.top-bar-box-noir-move-right {
  animation-name: noir-moveRight;
  animation-duration: 1s; /* Adjust the duration as needed */
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  cursor: pointer;
}

.top-bar-box-noir-move-right:hover {
  margin-right: -0.5vw;
  height: 90px;
}

.background-container {
  width: 200vw;
  height: 100%;
  position: fixed;
  display: flex;
  overflow: hidden;
}

.background-container-forward {
  animation: swipe-background-right 1s forwards;
}

.background-container-reverse {
  animation: swipe-background-left 1s forwards;
}

@keyframes swipe-background-right {
  0% {
    left: 0vw;
  }
  100% {
    left: -100vw;
  }
}

@keyframes swipe-background-left {
  0% {
    left: -100vw;
  }
  100% {
    left: 0vw;
  }
}

.background-container-noir {
  width: 50%;
  background: linear-gradient(to bottom, var(--dark-grey), var(--black));
}

.background-container-blanc {
  width: 50%;
  background: linear-gradient(
    to bottom,
    var(--light-background),
    var(--pale-grey)
  );
}

.card-grid {
  display: grid;
  gap: 20px;
  padding: 20px;
  margin: auto;
  width: 100%;
}

.main-card-grid {
  grid-template-columns: repeat(4, minmax(300px, 1fr));
  grid-template-rows: repeat(3, 250px);
}

.price-card-grid {
  margin-top: 20px;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
  grid-template-rows: repeat(1, 600px);
}

.card-main-1 {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
}
.card-main-2 {
  grid-column: 3 / span 1;
}
.card-main-3 {
  grid-column: 3 / span 1;
}

.card-main-4 {
  grid-column: 4 / span 1;
  grid-row: 2 / span 2;
}
.card-main-5 {
  grid-column: 1 / span 1;
  grid-row: 3 / span 1;
}
.card-main-7 {
  grid-row: 1 / span 1;
  grid-column: 4 / span 1;
}
.card-main-6 {
  grid-column: 2 / span 2;
  grid-row: 3 / span 1;
}
.main-content-card {
  padding: 24px;
}

@keyframes top-bar-pic-to-small {
  0% {
    height: 350px;
  }

  100% {
    height: 100px;
  }
}

@keyframes top-bar-pic-to-big {
  0% {
    height: 100px;
  }

  100% {
    height: 350px;
  }
}

.top-bar-pic {
  position: relative;
}

.top-bar-pic-small {
  animation: top-bar-pic-to-small 0.5s forwards;
}

.top-bar-pic-big {
  animation: top-bar-pic-to-big 0.5s forwards;
}

@keyframes top-bar-title-to-small {
  0% {
    font-size: 15em;
  }

  100% {
    font-size: 2em;
  }
}

@keyframes top-bar-title-to-big {
  0% {
    font-size: 2em;
  }

  100% {
    font-size: 15em;
  }
}

.top-bar-title-small {
  animation: top-bar-title-to-small 0.5s forwards;
}

.top-bar-title-big {
  animation: top-bar-title-to-big 0.5s forwards;
}
.top-bar-box-content {
  display: flex;
  flex-direction: row-reverse;
  height: 100%;
  width: 100%;
}

.main-content-card-image-background {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.33),
    rgba(255, 255, 255, 0)
  );
}

.main-content-card img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px;
}

.payment-card-background {
  width: 100%;
  background-image: url("../public/payment_back.png");
  background-repeat: "no-repeat";
  background-size: cover;
  height: 100%;
  border-radius: 12px;
  grid-column: 1;
  grid-row: 1;
}

.payment-card-foreground {
  backdrop-filter: blur(6px) brightness(0.5);
  background: linear-gradient(to bottom, var(--dark-grey66), var(--dark-grey));
  width: 100%;
  height: 100%;
  color: var(--light-background);
  border-radius: 12px;
  grid-column: 1;
  grid-row: 1;
}

.payment-card-foreground-blanc {
  background: linear-gradient(
    to bottom,
    var(--primary-alpha),
    var(--dark-grey)
  );
}

.payment-area-header {
  margin-top: 150px;
}


.swizzle-divider {
  width: 80%;
  margin: auto;
  padding: 20px 10%;
}
.emphasised-underline-order {
  color: var(--dark-grey);
  text-align: center;
  background-size: 16% 18%;
  height: 70px;
  background-position: 50% 88%;
}
