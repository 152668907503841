@media (max-width: 550px) {
  .top-bar-layout {
    width: 100%;
    grid-template-columns: 70px calc(100% - 140px) 70px;
    grid-template-rows: 80px;
    align-items: center;
    text-align: start;
    width: 100%;
    border-radius: 0px;
    padding: 3px 6px;
  }

  .top-bar-layout-min {
    height: 50px;
    grid-template-rows: 40px;
  }

  .top-bar-logo-min {
    margin-bottom: -8px;
  }

  .buy-now-btn {
    grid-row: 1;
    grid-column: 3;
    padding: 0px;
    margin: 0;
    justify-self: center;
  }
  .top-bar-box-container {
    top: 88px;
  }

  .main-content {
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .main-card-grid {
    grid-template-columns: repeat(2, minmax(80px, 180px));
    grid-template-rows: repeat(6, minmax(80px, 180px));
    width: auto;
    grid-gap: 3px;
  }
  .main-content-card {
    padding: 6px;
    height: 100%;
    width: 100%;
    font-size: 0.9em;
  }

  .price-card-grid {
    margin-top: 60px;
    width: 90%;
    grid-template-rows: 600px;
    grid-auto-flow: row;
    /* width: 100vw; */
    grid-template-columns: repeat(3, minmax(250px, 1fr));
    grid-auto-flow: column;
    /* grid-auto-flow: row; */
    overflow-x: scroll;
  }

  .card-main-1 {
    grid-column: 1 / span 2;
    grid-row: 1 / span 2;
  }
  .card-main-2 {
    grid-column: 1 / span 1;
    grid-row: 3;
  }
  .card-main-3 {
    grid-column: 1 / span 1;
    grid-row: 4;
  }
  .card-main-4 {
    grid-column: 2 / span 1;
    grid-row: 3 / span 2;
  }
  .card-main-5 {
    grid-column: 1 / span 1;
    grid-row: 5;
  }
  .card-main-7 {
    grid-column: 2 / span 1;
    grid-row: 5;
  }
  .card-main-6 {
    grid-column: 1 / span 2;
    grid-row: 6;
  }

  .top-bar-box {
    width: 78vw;
    padding: 24px;
    height: 80px;
    margin: 12px 1vw;
    top: 100px;
    background-size: 273%;
    background-position: 30% 63%;
  }
  @keyframes top-bar-title-to-small {
    0% {
      font-size: 7em;
    }

    100% {
      font-size: 2em;
    }
  }

  @keyframes top-bar-title-to-big {
    0% {
      font-size: 2em;
    }

    100% {
      font-size: 7em;
    }
  }

  .payment-area-header {
    margin-top: 30px;
  }

  .emphasised-underline-order {
    background-size: 88% 20%;
  }
}
