.footer-container {
    padding: 0px 10%;
    background: var(--dark-grey);
    color: var(--light-grey);
    min-height: 300px;
    padding-top: 20px;
  }
  
  
  .footer-content {
    /* Add your styles for the footer content here */
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 1200px;

  }
  
  .footer-content-left {
    /* Add your styles for the left side of the footer content here */
  }
  
  .footer-content-left h2 {
    /* Add your styles for the h2 elements in the left side of the footer content here */
    
  }

  .footer-content-left a {
    color: var(--light-grey);
    
  }
  
  .footer-content-right {
    /* Add your styles for the right side of the footer content here */
  }
  
  .footer-content-right h2 {
    /* Add your styles for the h2 elements in the right side of the footer content here */
    
  }
  
  .social-media {
    /* Add your styles for the social media links here */
    display: flex;
    gap: 10px;
  }
  
  .social-media a {
    /* Add your styles for the links in the social media section here */
    color: #333;
    text-decoration: none;
  }
  
  .footer-bottom {
    /* Add your styles for the bottom of the footer here */
    width: 100%;
    max-width: 1200px;
    text-align: center;
    color: #333;
    margin-top: 20px;
  }