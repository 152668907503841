.nav-bar {
  /* Add your styles for the nav bar here */

  padding: 0 15%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.nav-bar ul {
  /* Add your styles for the unordered list here */
  list-style-type: none;
  display: grid;
  gap: 24px;
  grid-auto-flow: column;
  margin: 0;
  padding: 0;
}

.nav-bar ul li {
  /* Add your styles for the list items here */
}

.nav-bar ul li a {
  /* Add your styles for the links here */
  text-decoration: none;
  color: var(--light-grey);
  font-size: 1.2em;
  font-weight: bold;
}
.nav-bar ul li a:hover {
  /* Add your styles for the links here */
  color: var(--primary);
}

@media screen and (max-width: 550px) {
  .nav-bar {
      visibility: hidden;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: start;
      width: 100%;
      grid-row: 2;
      grid-column: 2 / span 3;
      margin-left: -45px;
  }
}
