/* Styles for the container div */
.how-it-works {
    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* Styles for the heading */
  .how-it-works h1 {
    
  }
  
  /* Styles for the steps container */
  .how-it-works-steps {
    /* Add your styles for the steps container here */
  }

  
  /* Styles for individual steps */
  .how-it-works-step {
        /* width: auto; */
    /* width: 100px; */
    margin: 40px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  /* Styles for step heading */
  .how-it-works-step h2 {
    margin: 2px
  }
  
  /* Styles for step description */
  .how-it-works-step p {
    margin: 5px
  }